<template>
	<div>
		<CRow>
			<!-- Create Button -->
			<CCol md="12" class="mb-4">
				<CRow align-horizontal="end">
					<router-link
						:to="{ name: ROUTE_NAME.PROMOTION_BANNER_KEY_CREATE }"
						class="link-button"
					>
						<CButton class="btn-new-product-family transparent ml-3" color="secondary">
							<CIcon class="icon-left" name="cil-plus" />
							Create promotion banner key
						</CButton>
					</router-link>
				</CRow>
			</CCol>

			<!-- Search -->
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						data-test-id="attribute-keys-search"
						type="text"
						placeholder="Search"
					>
						<template #prepend-content>
							<button
								type="submit"
								class="btn-search"
							>
								<FontAwesomeIcon :icon="['far', 'search']" />
							</button>
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					class="ml-3"
					data-id="filter-button"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>

		<!-- Filter -->
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="bg-gray-100 rounded-sm pt-3 px-3 mb-3"
						data-id="show-filter-box"
					>
						<CRow>
							<CCol md="3">
								<BaseInputDate
									:value="dateRange"
									label="Date"
									placeholder="Start date - End date"
									mode="range"
									@input="handleFilterDateChange"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="status"
									:options="STATUS_OPTIONS"
									:allow-empty="false"
									:searchable="false"
									label="name"
									label-drop-down="Status"
									track-by="value"
									class="select-custom"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="period"
									:options="PERIOD_OPTIONS"
									:allow-empty="false"
									:searchable="false"
									label="name"
									label-drop-down="Period"
									track-by="value"
									class="select-custom"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>

		<!-- Search result -->
		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="Promotion banner keys found"
					reset-filter-btn-text="Reset search"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>

		<!-- Table -->
		<BaseTable
			:is-loading="list.isLoading"
			:fields="FIELDS"
			:items="promotionBannerKeyList"
			:pagination="{
				pages: list.meta.lastPage,
				activePage: list.meta.currentPage
			}"
			class="table-custom"
			striped
			:link-to="ROUTE_NAME.PROMOTION_BANNER_KEY_EDIT"
			@onPaginationClick="handlePageChange"
		>
			<template #no-items-view>
				<div class="empty-table-element">
					<p class="subtitle">
						{{ $t("global.searchNotFound", { field: "promotion banner key" }) }}
					</p>
				</div>
			</template>
			<template #period="{item}">
				<CBadge class="badge-period" :color="getPeriodColor(item)">
					{{ BANNER_PERIODS[item].name }}
				</CBadge>
			</template>
			<template #isActive="{item}">
				<CBadge class="badge-status" :color="BANNER_STATUSES[item].value">
					{{ BANNER_STATUSES[item].name }}
				</CBadge>
			</template>
		</BaseTable>

		<!-- Modal section -->
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deletePromotionBannerKey.bind(null, selectedKeyId)"
			title="Remove this promotion banner key?"
			description="Removing this promotion banner key will also remove it and all its values from all products."
			@onSuccess="handleDeleteSuccess"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
	cleanObject,
	cloneDeep,
	randomSearchString,
	hasSearchFilter,
	convertDateRangeToUTC,
} from '../assets/js/helpers';
import BaseModalConfirmDelete from '../components/BaseModalConfirmDelete.vue';

import { ROUTE_NAME } from '../enums/route';
import {
	STATUS_OPTIONS,
	BANNER_PERIOD_COLOR,
	BANNER_STATUSES,
	PERIOD_OPTIONS,
	BANNER_PERIODS,
} from '../enums/banners';

const FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id th-mw-30' },
	{ key: 'name', label: 'Promotion banner', class: 'col-name th-mw-250' },
	{ key: 'period', label: 'Period', class: 'col-period' },
	{ key: 'startDateDisplay', label: 'Start date', class: 'col-date' },
	{ key: 'endDateDisplay', label: 'End date', class: 'col-date' },
	{ key: 'isActive', label: 'Status', class: 'col-status' },
];

export default {
	name: 'PromotionBannerKeys',
	components: {
		BaseModalConfirmDelete,
	},
	data() {
		return {
			ROUTE_NAME,
			FIELDS,

			PERIOD_OPTIONS,
			STATUS_OPTIONS,
			BANNER_PERIOD_COLOR,
			BANNER_STATUSES,
			BANNER_PERIODS,

			selectedKeyId: null,
			isShowFilter: false,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				is_active: (Number(this.$route.query.is_active) === 0 || Number(this.$route.query.is_active) === 1)
					? Number(this.$route.query.is_active)
					: null,
				period_status: this.$route.query.period_status,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		...mapState('promotionBannerKeys', {
			list: 'list',
			create: 'create',
		}),
		...mapGetters({
			promotionBannerKeyList: 'promotionBannerKeys/promotionBannerKeyList',
		}),
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		status: {
			get() {
				return STATUS_OPTIONS.find((option) => option.value === this.queryParams.is_active);
			},
			set(status) {
				this.queryParams.is_active = status.value;

				this.updateUrlParams();
			},
		},
		period: {
			get() {
				return PERIOD_OPTIONS.find((period) => period.value === this.queryParams.period_status);
			},
			set(period) {
				this.queryParams.period_status = period.value;

				this.updateUrlParams();
			},
		},
		dateRange() {
			if (!this.queryParams.start_at || !this.queryParams.end_at) {
				return null;
			}

			return {
				start: new Date(this.queryParams.start_at),
				end: new Date(this.queryParams.end_at),
			};
		},
	},
	created() {
		this.getPromotionBannerKeys(this.queryParams);
	},
	methods: {
		...mapActions({
			getPromotionBannerKeys: 'promotionBannerKeys/getPromotionBannerKeys',
			createPromotionBannerKey: 'promotionBannerKeys/createPromotionBannerKey',
			deletePromotionBannerKey: 'promotionBannerKeys/deletePromotionBannerKey',
		}),
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query });
		},
		handleSearch() {
			this.queryParams.page = null;

			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;

			this.updateUrlParams();
		},
		async handleDeleteSuccess() {
			this.selectedKeyId = null;
			this.queryParams.q = '';
			this.queryParams.page = null;
			await this.getPromotionBannerKeys(this.queryParams);
		},
		openModalDelete(promotionBannerKeyId) {
			this.selectedKeyId = promotionBannerKeyId;
			this.$refs['modal-remove'].open();
		},
		getPeriodColor(period) {
			return BANNER_PERIOD_COLOR[period];
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				r: null,
				is_active: null,
				period_status: null,
				start_at: null,
				end_at: null,
			};

			this.updateUrlParams();
		},
		handleFilterDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.start_at = start;
			this.queryParams.end_at = end;

			this.updateUrlParams();
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-wrapper {
		max-width: rem(132);
	}

	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}

	.icon-trash {
		color: $color-black-25;
	}
</style>
